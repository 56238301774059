//Animações inicio
const topBg = document.querySelector('.top__bg');
const topTitle = document.querySelector('.top__title');
const topSubtitle = document.querySelector('.top__subtitle');
const building = document.querySelector('.building');
const clock = document.querySelector('.clock');
const handShake = document.querySelector('.hand');
const textHeader = document.querySelector('.top__text');
const infoProject = document.querySelectorAll('.info__project');
const infoText = document.querySelectorAll('.info__text');
const headerButton = document.querySelector('.top__button__position');
const contact = document.querySelector('#contact');
/* const textInfo = document.querySelector('.info-img'); */
const titleInfo = document.querySelectorAll('.info-text');
const textsInfo = document.querySelectorAll('.info-text-content');
const mobile = document.querySelector('#mobile')
// const mobileTitle = document.querySelector('#mobile__title');
// const swiperAnimate = document.querySelector('.swiper');
const information = document.querySelector('.information')
const counter1 = document.getElementById('counter1');
const counter2 = document.getElementById('counter2');
const counter3 = document.getElementById('counter3');


// Hexagon elements - development cicle

const hexagonsDarkblueEl = document.querySelectorAll('.hexagon-darkblue');
const hexagonsLightblueEl = document.querySelectorAll('.hexagon-lightblue');
const hexagonsGoldEl = document.querySelectorAll('.hexagon-container');

// For some reason, the observer was bugged out with the hexagon container and triggering when it should not
// So this workround was made, so it can trigger when the viewport reachs the middle of the section
const mainHexagonContainerTrigger = document.querySelector('.observer-circle-trigger');

const hexagonList = [...hexagonsDarkblueEl, ...hexagonsLightblueEl];

// Circle elements - development cicle

const circleElements = document.querySelectorAll('.development-cicle__circle-content');

let isPopupActive = false;
let canDeactivatePopup = true;


for (let index = 0; index < infoProject.length; index++) {
  infoProject[index].style.opacity = "0"
}

headerButton.style.opacity = "0"
/* textInfo.style.opacity="0" */
building.style.animationDelay = "0.5s"
clock.style.animationDelay = "0.5s"
handShake.style.animationDelay = "0.5s"
headerButton.style.animationDelay = "0.1s"
/* textInfo.style.animationDelay = "0.5s" */
// mobileTitle.style.animationDelay = "1s"
// swiperAnimate.style.animationDelay = "1.5s"



topBg.classList.add('animate__animated', 'animate__bounceInDown')
building.classList.add('animate__animated', 'animate__bounceInLeft');
clock.classList.add('animate__animated', 'animate__fadeIn');
handShake.classList.add('animate__animated', 'animate__backInRight');
// textHeader.style.opacity="0"
handShake.addEventListener('animationend', () => {
  for (let index = 0; index < infoProject.length; index++) {
    infoProject[index].classList.add('animate__animated', 'animate__fadeIn')
  }
});

for (let index = 0; index < infoProject.length; index++) {
  infoProject[index].addEventListener('animationend', () => {
    const infoProjectCard = document.querySelector('.info__project__container')

    if (infoProjectCard && typeof infoProjectCard !== "undefined") {
      const observer = new IntersectionObserver((entries) => {

        increment(0, 200, counter1);
        increment(0, 10, counter2);
        increment(0, 90.5, counter3); //está com um bug no valor 90, se for 90, ele vai chainAnimationOnHeaderButtonara 89, se for 91 fica em 91
      })

      observer.observe(infoProjectCard)
    }

    function increment(i, max, element) {
      if (i > max) return;
      setTimeout(function () {
        element.innerText = Math.round(i);
        increment(i + (max / 100), max, element);
      }, 10)
    }

    headerButton.classList.add('animate__animated', 'animate__fadeIn');
    headerButton.addEventListener('animationend', chainAnimationOnHeaderButton)
  })
}
function chainAnimationOnHeaderButton() {
  
  headerButton.style.opacity = "1"
  headerButton.style.animationDelay = "0s"
  headerButton.classList.remove('animate__fadeIn');
  headerButton.classList.add('active');

  document.querySelector('.top__button').classList.add('active');
  headerButton.removeEventListener("mousedown", chainAnimationOnHeaderButton, true)
}

/* if (textInfo && typeof textInfo !== "undefined"){ 

  const textInfoObserver = new IntersectionObserver ((entries) => {
    const [entry] = entries;
    if (entry.isIntersecting && !textInfo.classList.contains('animate__animated')) {
      textInfo.classList.add('animate__animated', 'animate__fadeIn');
    } 
    //contact.classList.add('animate__animated', 'animate__rotateInDownRight');
  })

  const textInfoObserverOptions = {
    root: null,
    threshold: 1,
    rootMargin: "-100px",
  };

  textInfoObserver.observe(textInfo, textInfoObserverOptions)
} */

// Titles animation
if (titleInfo.length && typeof titleInfo !== "undefined") {
  titleInfo.forEach(titleElement => {
    titleElement.style.opacity = "0"
    titleElement.style.animationDelay = "0.5s"

    const titleInfoObserver = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && !titleElement.classList.contains('animate__animated')) {
        titleElement.classList.add('animate__animated', 'animate__fadeIn');
      }
      /* contact.classList.add('animate__animated', 'animate__rotateInDownRight'); */
    })

    const titleInfoObserverOptions = {
      root: null,
      threshold: 1,
      rootMargin: "-100px",
    };

    titleInfoObserver.observe(titleElement, titleInfoObserverOptions)
  });
}
// Text sections animation
if (textsInfo.length && typeof textsInfo !== "undefined") {
  textsInfo.forEach(textElement => {
    textElement.style.opacity = "0"
    textElement.style.animationDelay = "0.5s"

    const textInfoObserver = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && !textElement.classList.contains('animate__animated')) {
        textElement.classList.add('animate__animated', 'animate__fadeIn');
      }
      /* contact.classList.add('animate__animated', 'animate__rotateInDownRight'); */
    })

    const textInfoObserverOptions = {
      root: null,
      threshold: 1,
      rootMargin: "-100px",
    };

    textInfoObserver.observe(textElement, textInfoObserverOptions)
  });
}

// Hexagons animation TODO - Ajustar a animacao, o observer
// está sendo ativado cedo demais por algum motivo
if (hexagonList.length && hexagonsGoldEl.length && typeof hexagonList !== "undefined" && mainHexagonContainerTrigger !== "undefined") {
  hexagonList.forEach(hexagonElement => {
    hexagonElement.style.opacity = "0"
    hexagonElement.style.animationDelay = Math.random().toFixed(2) + 's';
  });


  hexagonsGoldEl.forEach(hexagonGoldEl => {
    hexagonGoldEl.style.opacity = '0';
    hexagonGoldEl.style.animationDelay = '1.5s';
  }) 

  const mainHexagonContainerTriggerObserver = new IntersectionObserver((entries) => {
    const [entry] = entries;

    if (entry.isIntersecting && !hexagonList[0].classList.contains('animate__animated')) {
      hexagonList.forEach(hexagonElement => {
        hexagonElement.classList.add('animate__animated', 'animate__fadeIn');
        // Add effect of going up and down
        hexagonElement.onanimationend = () => {
          hexagonElement.style.opacity = 1;
          hexagonElement.style.animationDelay = '1.3s';
          hexagonElement.style.animation = 'testar ease-in-out 1.5s infinite alternate';
        }
      })

      hexagonsGoldEl.forEach(hexagonGoldEl => {
        hexagonGoldEl.classList.add('animate__animated', 'animate__fadeIn');
      });

    }
    /* contact.classList.add('animate__animated', 'animate__rotateInDownRight'); */
  })

  const mainHexagonContainerTriggerObserverOptions = {
    root: document.body,
    threshold: 1,
    rootMargin: "1000px",
  };

  mainHexagonContainerTriggerObserver.observe(mainHexagonContainerTrigger, mainHexagonContainerTriggerObserverOptions);
}

// Circle animations
if (circleElements.length && typeof circleElements !== "undefined") {
  circleElements.forEach((circleElement, key) => {
    /* circleElement.style.opacity = 0; */

    const circleImage = circleElement.querySelector('.development-cicle__circle-content__circle-line');
    const belowLine = circleElement.querySelector('.below-line');
    const textInfo = circleElement.querySelector('.development-cicle__circle-content__info');

     circleImage.style.opacity = 0;
     belowLine.style.opacity = 0;
     circleImage.style.transform = 'scale(1.2)';

     belowLine.style.height = '0px';
     textInfo.style.opacity = 0;

     circleImage.style.transitionDelay = '.9s';
     circleImage.style.transitionDuration = '500ms'

     textInfo.style.transitionDelay = '1.5s';
     textInfo.style.transitionDuration = '.5s';
     textInfo.style.marginLeft = '-10px';

     belowLine.style.transitionDelay = '1.5s';
     belowLine.style.transitionDuration = '.5s';



     let fullLineHeight = '60px';

     if(key + 1 == circleElements.length) {
      
      fullLineHeight = '90px';
     }

    const circleElementObserver = new IntersectionObserver((entries) => {
      const [entry] = entries;
      // The animate__animated is still used here to sinalize to the code
      // that there's no need to rerun it anymore
      if (entry.isIntersecting && !circleElement.classList.contains('animate__animated')) {
        circleElement.classList.add('animate__animated');
        
        circleImage.style.opacity = '1';
        circleImage.style.transform = 'scale(1)';

        textInfo.style.marginLeft = '0px';
        textInfo.style.opacity = '1';

        belowLine.style.opacity = '1';
        belowLine.style.height = fullLineHeight;
      }
    });
  
    const circleElementObserverOptions = {
      root: null,
      threshold: 1,
      rootMargin: "70px",
    };

    circleElementObserver.observe(circleElement, circleElementObserverOptions);

  });
}



// if (mobile && typeof mobile !== "undefined"){ 

//   const mobileObserver = new IntersectionObserver ((entries) => {
//     const [entry] = entries;
//     if (entry.isIntersecting && !mobile.classList.contains('animate__animated')) {

//       mobileTitle.classList.add('animate__animated', 'animate__lightSpeedInLeft');
//       swiperAnimate.classList.add('animate__animated', 'animate__lightSpeedInRight');
//     } 
//     /* contact.classList.add('animate__animated', 'animate__rotateInDownRight'); */
//   })

//   const mobileObserverOptions = {
//     root: null,
//     threshold: 1,
//     rootMargin: "-100px",
//   };

//   mobileObserver.observe(mobile, mobileObserverOptions)
// }


function activateOverlay() {
  const overlay = document.querySelector(".overlay");
  if (overlay) {
    overlay.classList.add("active");
    document.body.style.overflow = "hidden";
  }
}

function deactivateOverlay() {
  const overlay = document.querySelector(".overlay");
  if (overlay) {
    overlay.classList.remove("active");
    document.body.style.overflow = "";
  }
}

//Animações fim

//Carrossel inicio
const swiper = new Swiper(".swiper", {
  autoplay: { delay: 4000 },
  slidesPerView: 1,
  center: 0,
  loop: true,
  grabCursor: true,
  centeredSlides: true,
  slideActiveClass: "active",
  navigation: {
    nextEl: ".next",
    prevEl: ".prev"
  },
  // Media,

  breakpoints: {
    // when window width is >= 320px
    1024: {
      slidesPerView: 3,
      spaceBetween: 0
    }
  }
});
//Carrossel fim




//send email start
// begin alert popup
/* var el = document.getElementById("alert-pup");

function alertPup(title, message, classMessage) {
  el.animate(
    [{ transform: "translateY(0px)" }, { transform: "translateX(-300px)" }],
    {
      duration: 3000,
      iteration: 1,
    }
  );

  el.innerHTML = `
    <div class="toast ${classMessage}">
      <div>
        <h2>${title}</h2>
        <p>${message}</p>
      </div>
    </div>`;

  setTimeout(() => cleanPup(), 6000);
}

function cleanPup() {
  el.innerHTML = "";
} */
// end alert popup

// begin contact form
function contactForm() {
  //
  function setSpanError(bool, elInput, elErrorSpan) {
    if (bool) {
      elInput.classList.add("form-error-message");
      elErrorSpan.classList.add("active");
    } else {
      elInput.classList.remove("form-error-message");
      elErrorSpan.classList.remove("active");
    }
  }

  // begin input name
  const nameInput = document.getElementById("contact-name");
  const nameErrorSpan = document.getElementById("error-input-name");

  function isInvalidName() {
    const nameValidator = /[A-Za-z]{3}/;
    let isNameValid = nameValidator.test(nameInput.value);

    if (isNameValid) {
      return false;
    } else {
      return true;
    }
  }
  // end input name

  // begin input email
  const emailInput = document.getElementById("contact-mail");
  const emailErrorSpan = document.getElementById("error-input-email");

  function isInvalidEmail() {
    const emailValidator =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isEmailValid = emailValidator.test(emailInput.value.toLowerCase());

    if (isEmailValid) {
      return false;
    } else {
      return true;
    }
  }
  // end input email

  // begin mensagem
  const messageInput = document.getElementById("contact-mensage");
  const messageErrorSpan = document.getElementById("error-input-mensage");

  function isInvalidMessage() {
    if (messageInput.value == null || messageInput.value == "") {
      return true;
    } else {
      return false;
    }
  }
  // end mensagem

  // begin input telefone
  const phoneInput = document.getElementById("contact-phone");
  const phoneErrorSpan = document.getElementById("error-input-phone");

  function isInvalidPhoneNumeber() {
    const phoneValidator =
      /^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
    let isPhoneNumerValid = phoneValidator.test(phoneInput.value.toLowerCase());

    if (isPhoneNumerValid) {
      return false;
    } else {
      return true;
    }
  }

  function maskPhoneInput() {
    let inputValue = phoneInput.value;

    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.replace(/^(\d{2})(\d)/g, "($1) $2");
    inputValue = inputValue.replace(/(\d)(\d{4})$/, "$1-$2");

    phoneInput.value = inputValue;
  }

  phoneInput.oninput = maskPhoneInput;
  // end input telefone

  //
  function handleSpanError() {
    setSpanError(isInvalidName(), nameInput, nameErrorSpan);
    setSpanError(isInvalidEmail(), emailInput, emailErrorSpan);
    setSpanError(isInvalidPhoneNumeber(), phoneInput, phoneErrorSpan);
    setSpanError(isInvalidMessage(), messageInput, messageErrorSpan);
  }

  //
  function validateInputFromForm() {
    if (
      isInvalidName() ||
      isInvalidEmail() ||
      isInvalidPhoneNumeber() ||
      isInvalidMessage()
    ) {
      return false;
    } else {
      return true;
    }
  }

  function activateOverlay() {
    const overlay = document.querySelector(".overlay");
    if (overlay) {
      overlay.classList.add("active");
      document.body.style.overflow = "hidden";
    }
  }

  function deactivateOverlay() {
    const overlay = document.querySelector(".overlay");
    if (overlay) {
      overlay.classList.remove("active");
      document.body.style.overflow = "";
    }
  }

  function activatePopup(initialStatusMessage) {
    const modalPopup = document.querySelector(".modal-mailresponse");
    if (modalPopup) {
      const elModalText = modalPopup.querySelector(
        ".modal-mailresponse-text"
      );

      if (elModalText) {
        elModalText.innerText = "";

        if (initialStatusMessage)
          elModalText.innerText = initialStatusMessage;

        modalPopup.classList.add("active");
      }
    }
    isPopupActive = true;
  }

  function deactivatePopup() {
    const modalPopup = document.querySelector(".modal-mailresponse");
    if (modalPopup) {
      modalPopup.querySelector(".modal-mailresponse-text").innerText = "";
      modalPopup.classList.remove("active");
    }
    isPopupActive = false;
  }

  function deactivateActiveModal() {
    if (canDeactivatePopup) {
      if (isPopupActive) deactivatePopup();

      deactivateOverlay();
    }
  }

  const overlayEl = document.querySelector(".overlay");
  if (overlayEl) {
    overlayEl.addEventListener("click", deactivateActiveModal);
    overlayEl.addEventListener("touch", deactivateActiveModal);
  }

  // begin action send message
  function sendMessageLPM(e) {
    e.preventDefault();
    handleSpanError();
    if (validateInputFromForm())
      sendEmail();
  }

  function changePopupText(message) {
    const modalMailresponseTextEl = document.querySelector(
      ".modal-mailresponse-text"
    );
    if (modalMailresponseTextEl)
      modalMailresponseTextEl.innerText = message;
  }

  function startLoadingOnButton(btn) {
    btn.disabled = true;
    btn.classList.add('fa-fade');
    btn.innerText = 'Enviando...'
  } 

  function endLoadingOnButton(btn) {
    btn.classList.remove('fa-fade');
    btn.innerText = 'Enviado!'
  }

  const buttonSend = document.querySelector(".button-send");
  buttonSend.onclick = sendMessageLPM;
  // end action send message

  // TODO: 3- validar retorno do envio de email
  async function sendEmail(e) {
    //
    if (e && e instanceof Event) {
      e.preventDefault();
    }

    const functions = firebase.functions();
    // functions.useEmulator("localhost", 5001);
    var sendMessageLPM = functions.httpsCallable("sendMessageLPM");
    var sendGoogleSheetLPM = functions.httpsCallable("sendGoogleSheetLPM");

    const _data = {
      name: nameInput.value,
      email: emailInput.value,
      phone: phoneInput.value,
      message: messageInput.value
    };

    // handle data message

    // messageInput && messageInput.value
    //   ? (_data.message = `Telefone para contato: ${phoneInput.value}. Mensagem: ${messageInput.value}.`)
    //   : (_data.message = "Telefone para contato: " + phoneInput.value + ".");

    // _data.message = _data.message + ":" + fuctionInput.value;

    startLoadingOnButton(buttonSend);

    grecaptcha.ready(() =>
      grecaptcha
        .execute("6LfQEvMjAAAAAPAKUDJjy0ZZ9Z9W7H1LM43PlyQD", {
          action: "submit",
        })
        .then((token) => {
          //

          canDeactivatePopup = false;
          activateOverlay();
          activatePopup("Enviando...");

          _data.token = token;

          // action sendMessageLPM
          sendMessageLPM({ ..._data })
            .then((response) => {
              canDeactivatePopup = true;
              window.location.href =
                "https://lpm.targetwork.com.br/obrigado";
              // TODO: cleanFormContact();
            })
            .catch((error) => {
              changePopupText(`Houve um erro no envio da mensagem. 
              Tente novamente mais tarde, ou nos contate por nossas redes sociais!`);
            })
            .finally(() => {
              endLoadingOnButton(buttonSend);
              canDeactivatePopup = true;
            });

          sendGoogleSheetLPM({ ..._data })
            .then((response) => {
              console.log("response googleSheet", response);
            })
            .catch((error) =>
              console.log("error sendGoogleSheetLPM", error)
            );
        })
        .catch((err) => {
          // Promise.reject(err)
          // console.log(`ERROR 4323 ::: ${err}`);
        })
    );

    return;
    // TODO: tratar retorno do erro
    /* if(error) {
          return changePopupText(`Houve um erro no envio da mensagem. 
            Tente novamente mais tarde, ou nos contate por nossas redes sociais!`); 
            canDeactivatePopup = true; 
      } */

    return false;
  }
}

contactForm();
// end contact form

//send email finish
